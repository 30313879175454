import animatedScrollTo from 'animated-scroll-to';
// import './component-modal';

// This gives anmiation to the nav scroll, shrinking the text and height.
const header = document.querySelector('header');
const parallaxBg1 = document.querySelector('#parallax-bg-1');
const parallaxBg2 = document.querySelector('#parallax-bg-2');
const parallaxBg3 = document.querySelector('#parallax-bg-3');
const homeButton = document.querySelector('.js-home');

window.addEventListener('scroll', function () {
  const offset = window.scrollY;

  if (offset > 30) {
    header.classList.add('js-header-offset');
  } else {
    header.classList.remove('js-header-offset');
  }

  // ANIMATIONS FOR SCROLL AND CLOUDS from https://codepen.io/MarcelSchulz/pen/lCvwq
  parallaxBg1.style.top = 0 - offset * 0.25 + 'px';
  parallaxBg2.style.top = 0 - offset * 0.4 + 'px';
  parallaxBg3.style.top = 0 - offset * 0.75 + 'px';
});

const innerBody = document.querySelector('.inner-body');
function resize() {
  innerBody.style.height = document.body.getBoundingClientRect().height + 'px';
}
window.addEventListener('resize', resize);
resize();

// --- put the latest date in
document.querySelector('.js-year').innerHTML = new Date().getFullYear();

// ---- scroll points

// --- we've hardcoded scroll points for three different breakpoints
// [large, medium, small]
// each row represents a new section (so it's itinerary, travel, regisrty)
const scrollPoints = [
  [1714, 1714, 870],
  [3952, 3364, 2600],
  [5905, 4600, 4000],
  [7115, 5330, 5400],
];

function getWidthPoint() {
  const w = window.outerWidth;
  if (w > 1400) return 0;
  if (w > 605) return 1;
  return 2;
}
// --- scroll to elements
// go through each scroll to element and figure out where we're going to scroll to
[...document.querySelectorAll('.js-scroll-to')].forEach((el, i) => {
  el.addEventListener('click', (e) => {
    const index = getWidthPoint();
    e.preventDefault();
    animatedScrollTo(scrollPoints[i][index]);
  });
});

homeButton.addEventListener('click', (e) => {
  e.preventDefault();
  animatedScrollTo(0);
});
